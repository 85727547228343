import { styled, css, typography, media } from '@pulse-web-ui/theme';
import { Button } from '@pulse-web-ui/button';
import { Input } from '@pulse-web-ui/input';

export const InputCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 8px;
  width: 100%;
`;

export const InputCodeRow = styled.div`
  display: flex;
  width: 100%;

  ${media.desktop} {
    max-width: 280px;
  }

  & > div:first-child {
    width: 100%;
  }
`;

export const StyledButton = styled(Button)<{
  error?: boolean;
}>`
  min-width: 56px;
  border-radius: ${({ theme }) =>
    `0 ${theme.common.radius12} ${theme.common.radius12} 0`};

  ${({ error, theme }) =>
    error &&
    css`
      border: 1px solid ${theme.colors.text.error};
      background: ${theme.colors.text.error};
      &:hover {
        background: ${theme.colors.text.error} !important;
        border: 1px solid ${theme.colors.text.error} !important;
      }
    `};
`;

export const StyledInput = styled(Input)<{
  error?: boolean;
}>`
  border-right: none;
  border-radius: ${({ theme }) =>
    `${theme.common.radius12} 0 0 ${theme.common.radius12}`};

  &:focus,
  &:hover {
    border-right: none;
  }
`;

export const StyledChangePhoneButton = styled(Button)`
  ${typography.regular14};
  line-height: 20px;
`;
