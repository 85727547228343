import { lazy, Suspense } from 'react';
import {
  StyledLongBlock,
  StyledLongLabel,
  StyledLongText,
  StyledParagraph,
  StyledCards,
  StyledCard,
  StyledCardImageBox,
  StyledCardText,
  StyledBottomLabel,
  StyledSocialButtons,
  StyledButtonText,
  StyledQRBox,
  StyledButton,
  SocialButton,
} from './payment-layout.styles';
import { WidgetContainer } from '@pulse-web-ui/containers';
import { Trans, useTranslation } from 'react-i18next';
import { SOCIAL } from '@shared/constants/social';
import { PaymentLayoutProps } from './payment-layout.types';
import { isBrowser } from 'react-device-detect';
import { domains } from '@shared/domains';
import { useIsDesktop, useStores } from '@shared/hooks';
import { isAppleSafari } from '@shared/utils/is-apple-safari';
import { analyticEvents } from '@app/web-analytic';
import { useTheme } from '@pulse-web-ui/theme';
import type { BaseThemeType } from '@pulse-web-ui/theme';
import { lazyIcons } from '@pulse-smart-components-kit/common';

const TelegramIcon = lazyIcons.Telegram2;
const VkIcon = lazyIcons.Vk2;

const DisplayQRButton = lazy(
  () => import('@pulse-web-ui/display-qr-button/lib/lazy')
);

export const PaymentLayout = ({
  policyText,
  policyLabel,
  items,
  isMobileApp,
}: PaymentLayoutProps) => {
  const {
    MainStore: {
      applicationStore: { sendSingleAnalyticEvent },
    },
  } = useStores();
  const { t } = useTranslation();
  const isDesk = useIsDesktop();
  const theme = useTheme() as BaseThemeType;

  const handleClick = (path: string) => {
    window.open(path);
  };

  const handleMobileAppClick = () => window.open(domains.mobileAppDeepLink);

  const handleMobileAppClickForBrowser = () => {
    const deepLink = window.envUrls.INSURANCE_MOBILE_APP_HOT_LINK;

    isAppleSafari()
      ? (window.location.href = deepLink)
      : window.open(deepLink, '_blank');

    sendSingleAnalyticEvent(analyticEvents.downloadApp);
  };

  const shareLifehack = isDesk
    ? t('COMMON:pay.shareLifehack')
    : t('COMMON:pay.shareLifehackMobile');

  return (
    <>
      <WidgetContainer>
        <StyledLongBlock>
          <StyledLongLabel>{policyLabel}</StyledLongLabel>
          <StyledLongText>{policyText}</StyledLongText>

          {isMobileApp && (
            <StyledButton
              variant="primary"
              isMobileApp={isMobileApp}
              label={t('COMMON:pay.backToMobileApp')}
              onClick={handleMobileAppClick}
            />
          )}

          {!isMobileApp && (
            <>
              {isBrowser ? (
                <StyledQRBox>
                  <Suspense>
                    <DisplayQRButton
                      buttonLabel={t('COMMON:pay.downloadApp')}
                      dropdownSubtitle={t('COMMON:pay.pointYourCamera')}
                    />
                  </Suspense>
                </StyledQRBox>
              ) : (
                <StyledButton
                  variant="primary"
                  label={t('COMMON:pay.downloadApp')}
                  onClick={handleMobileAppClickForBrowser}
                />
              )}
              <StyledParagraph>{t('COMMON:pay.inAppYouCan')}</StyledParagraph>
              <StyledCards>
                {items.map((item) => (
                  <StyledCard>
                    <StyledCardImageBox>{item.icon}</StyledCardImageBox>
                    <StyledCardText>{item.text}</StyledCardText>
                  </StyledCard>
                ))}
              </StyledCards>
              <StyledBottomLabel>
                <Trans>{shareLifehack}</Trans>
              </StyledBottomLabel>
              <StyledSocialButtons>
                <SocialButton
                  variant="primary"
                  onClick={() => handleClick(SOCIAL.telegram.path)}
                >
                  <Suspense>
                    <TelegramIcon
                      width={24}
                      color={theme.colors.icon.primary}
                    />
                  </Suspense>
                  <StyledButtonText>
                    {t('COMMON:pay.telegram')}
                  </StyledButtonText>
                </SocialButton>
                <SocialButton
                  variant="primary"
                  onClick={() => handleClick(SOCIAL.vk.path)}
                >
                  <Suspense>
                    <VkIcon width={24} color={theme.colors.icon.primary} />
                  </Suspense>
                  <StyledButtonText>
                    {t('COMMON:pay.vkontakte')}
                  </StyledButtonText>
                </SocialButton>
              </StyledSocialButtons>
            </>
          )}
        </StyledLongBlock>
      </WidgetContainer>
    </>
  );
};
