/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable indent */
import { Suspense, useEffect, useState } from 'react';
import type { FC } from 'react';
import { useTranslation } from 'react-i18next';

import { useTheme } from '@pulse-web-ui/theme';
import { Resend } from '..';
import {
  InputCodeRow,
  InputCodeWrapper,
  StyledButton,
  StyledInput,
  StyledChangePhoneButton,
} from './input-code.styles';
import { i18nDefaultValues } from '../../i18n';
import type { Props } from './input-code.types';
import { lazyIcons } from '@pulse-smart-components-kit/common';

const ArrowRight = lazyIcons.ArrowRight;

const EXPIRE_PERIOD = 30;
const OTP_MAX_LENGTH = 5;
const PLACEHOLDER = '00000';

export const InputCode: FC<Props> = ({
  value,
  onChange,
  changePhone,
  submitCode,
  disabled,
  isError,
  refetchAuthentication,
}) => {
  const { t } = useTranslation();
  const theme: any = useTheme();
  const [placeholder, setPlaceholder] = useState('');
  const [expiredTime, setExpiredTime] = useState<number>();

  const handleFocus = () => {
    setPlaceholder(PLACEHOLDER);
  };

  const handleBlur = () => {
    setPlaceholder('');
  };

  const handleSubmitCode = () => {
    submitCode();
  };

  useEffect(() => {
    setExpiredTime(Date.now() / 1000 + EXPIRE_PERIOD);
  }, []);

  useEffect(() => {
    if (String(value).length === 5) {
      handleSubmitCode();
    }
  }, [value]);

  const handleResend = () => {
    setExpiredTime(Date.now() / 1000 + EXPIRE_PERIOD);
    onChange('');
    refetchAuthentication(true);
  };

  const colorIcon =
    disabled || isError ? theme.colors.icon.inverse : theme.colors.icon.primary;

  return (
    <InputCodeWrapper>
      <InputCodeRow>
        <StyledInput
          noMargin
          label={t('SMART:Submit.labels.codeFromSMS', {
            defaultValue: i18nDefaultValues.Submit.labels.codeFromSMS,
          })}
          value={value}
          onChange={onChange}
          maxLength={OTP_MAX_LENGTH}
          placeholder={placeholder}
          onFocus={handleFocus}
          onBlur={handleBlur}
          type="numeric"
          inputMode="numeric"
          error={isError}
          disabled={disabled}
          hintObject={{
            message: isError
              ? t('SMART:Submit.errors.incorrectCodeFromSms', {
                  defaultValue:
                    i18nDefaultValues.Submit.errors.incorrectCodeFromSms,
                })
              : '',
          }}
        />
        <StyledButton
          variant="square"
          onClick={handleSubmitCode}
          isLoading={disabled}
          error={isError}
        >
          <Suspense>
            <ArrowRight width={30} color={colorIcon} />
          </Suspense>
        </StyledButton>
      </InputCodeRow>
      {expiredTime && (
        <Resend
          isExpiryTimestampText
          expiryTimestamp={expiredTime}
          onResend={handleResend}
        />
      )}
      <StyledChangePhoneButton variant="text" onClick={changePhone}>
        {t('SMART:Submit.labels.changePhone', {
          defaultValue: i18nDefaultValues.Submit.labels.changePhone,
        })}
      </StyledChangePhoneButton>
    </InputCodeWrapper>
  );
};
