import { styled } from '@pulse-web-ui/theme';

export const PromoAgreementStyle = styled.div`
  flex: 1;
`;

export const AgreementWrapper = styled.div`
  display: block !important;
`;

export const AgreementLink = styled.a`
  color: ${({ theme }) => theme.colors.text.interactive};

  &:hover {
    color: ${({ theme }) => theme.colors.text['interactive-clarified']};
  }
`;
