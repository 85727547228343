import { Suspense } from 'react';
import { t } from 'i18next';
import { lazyIcons } from '@pulse-smart-components-kit/common';

const MessageIcon = lazyIcons.RGSMessage;
const DocumentsIcon = lazyIcons.RGSLicence;

export const paymentCards = [
  {
    icon: (
      <Suspense>
        <MessageIcon width={64} />
      </Suspense>
    ),
    text: t('COMMON:pay.getConsultation'),
  },
  {
    icon: (
      <Suspense>
        <DocumentsIcon width={64} />
      </Suspense>
    ),
    text: t('COMMON:pay.viewUsefulMaterials'),
  },
];
