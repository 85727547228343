import { useCallback, useState } from 'react';
import type { FC, SyntheticEvent } from 'react';
import { StyledCheckbox } from '@shared/styles';
import { t } from 'i18next';
import { i18nDefaultValues } from '../../i18n';
import { PromoCode } from './promo-code';
import { ProductPrice } from '@pulse-smart-components-kit/common';
import { PromoCodeWrapper } from './promo-code.styles';

interface Props {
  price: ProductPrice;
  onChange: (promoCode: Partial<ProductPrice>) => void;
  onSubmit: (promoCode: string) => void;
  onClear: () => void;
}

export const PromoCodeOptional: FC<Props> = ({
  price,
  onChange,
  onSubmit,
  onClear,
}) => {
  const [hasPromoCode, setHasPromoCode] = useState(
    !!price.promoCode || !!price.applyedPromoCode
  );

  const handleHasPromoCode = useCallback(
    ({ currentTarget: { checked } }: SyntheticEvent<HTMLInputElement>) => {
      setHasPromoCode(checked);
      if (!checked) {
        onChange({
          promoCode: undefined,
          applyedPromoCode: undefined,
          isApplyedPromo: false,
        });
        onClear();
      }
    },
    [setHasPromoCode, onChange, onClear]
  );

  const handleLocalPromoCode = useCallback(
    (value: string) => {
      onChange({ promoCode: value, isApplyedPromo: false });
    },
    [onChange]
  );

  return (
    <PromoCodeWrapper>
      <div data-test="Promo-code-checkbox">
        <StyledCheckbox
          checked={hasPromoCode}
          onChange={handleHasPromoCode}
          label={t('SMART:Submit.labels.havePromoCode', {
            defaultValue: i18nDefaultValues.Submit.labels.havePromoCode,
          })}
        />
      </div>
      {hasPromoCode && (
        <PromoCode
          onSubmit={onSubmit}
          onChange={handleLocalPromoCode}
          price={price}
        />
      )}
    </PromoCodeWrapper>
  );
};

PromoCodeOptional.displayName = 'PromoCodeOptional';
