import { StyledCheckbox } from '@shared/styles';
import { Trans, useTranslation } from 'react-i18next';
import { USER_RECL_AGREEMENTS_URL } from '../../constants';

import { TooltipDirection, TriggerDirection } from '@pulse-web-ui/tooltip';

import {
  PromoAgreementStyle,
  AgreementWrapper,
  AgreementLink,
} from './promo-agreement.style';

import type { FC, SyntheticEvent } from 'react';
import { Props } from './promo-agreement.type';
import { i18nDefaultValues } from '../../i18n';

export const PromoAgreement: FC<Props> = ({ value, onChange, disabled }) => {
  const { t } = useTranslation();

  const handleChange = (event: SyntheticEvent) => {
    const target = event.target as HTMLInputElement;
    onChange(target.checked);
  };

  const ReclAgreement = (
    <AgreementWrapper>
      <Trans
        values={{ href: USER_RECL_AGREEMENTS_URL }}
        components={{ a: <AgreementLink /> }}
      >
        {t('SMART:Submit.hints.agreeReceiveOffers', {
          defaultValue: i18nDefaultValues.Submit.hints.agreeReceiveOffers,
        })}
      </Trans>
    </AgreementWrapper>
  );

  return (
    <PromoAgreementStyle>
      <StyledCheckbox
        checked={value}
        onChange={handleChange}
        label={ReclAgreement}
        disabled={disabled}
        tooltipOptions={{
          trigger: TriggerDirection.HOVER,
          direction: TooltipDirection.UP,
        }}
      />
    </PromoAgreementStyle>
  );
};
