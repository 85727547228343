/* eslint-disable indent */
import { css, styled, typography } from '@pulse-web-ui/theme';

export const Resend = styled.div`
  text-align: center;
  ${typography.regular14};
  line-height: 20px;
`;

export const ResentText = styled.div<{
  disabled?: boolean;
  seconds: number;
}>`
  ${typography.regular14};
  line-height: 20px;
  width: 100%;
  color: ${({ theme }) => theme.colors.text.secondary};
  text-align: left;

  &:hover {
    color: ${({ theme, seconds }) =>
      seconds > 0
        ? theme.colors.text.secondary
        : theme.colors.text['interactive-clarified']};
  }

  cursor: ${({ seconds }) => (seconds > 0 ? 'auto' : 'pointer')};
  ${({ seconds }) =>
    !(seconds > 0) &&
    css`
      color: ${({ theme }) => theme.colors.text.interactive};
    `}
`;
