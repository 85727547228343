import { media, mixins, styled, typography } from '@pulse-web-ui/theme';
import { Button } from '@pulse-web-ui/button';

export const PromoWrapper = styled.div`
  ${mixins.flex({ gap: 8 })};
  width: 100%;
`;

export const PromoTextInputWrapper = styled.div`
  flex: auto;
`;

export const PromoSubmitButton = styled(Button)`
  ${typography.medium18};
  line-height: 26px;
  padding: 14px 32px;

  ${media.desktop} {
    width: auto;
  }
  width: 100%;
`;

export const PromoCodeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  ${media.desktop} {
    gap: 24px;
  }
`;
